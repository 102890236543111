<ng-container *ngIf="!item.hidden">
    <li class="nav-item pcoded-menu-caption"><label>{{item.title}}</label></li>
    <ng-container *ngFor="let item of item.children">
        <!-- <app-nav-group *ngIf="item.type=='group'" [item]="item"></app-nav-group> -->
        <app-nav-collapse *ngIf="item.type=='collapse' && isAdmin && !isFranchise && item.id!='franchise-purchase'" [item]="item"></app-nav-collapse>
        <app-nav-collapse *ngIf="item.type=='collapse' && item.id!='inventory' && item.id!='cnf' && !isAdmin && !isFranchise && item.id!='franchise-purchase' " [item]="item "></app-nav-collapse>
        <app-nav-item *ngIf="item.type=='item' && isAdmin && !isFranchise && item.id!='franchise-purchase' " [item]="item "></app-nav-item>
        <app-nav-item *ngIf="item.type=='item' && !isAdmin && item.id!='commission-generate' && !isFranchise && item.id!='franchise-purchase' " [item]="item "></app-nav-item>
        <app-nav-collapse *ngIf="item.type=='collapse' && item.id=='wallet' && isFranchise " [item]="item "></app-nav-collapse>
        <app-nav-collapse *ngIf="item.type=='collapse' && item.id=='franchise-purchase' && isFranchise " [item]="item "></app-nav-collapse>
    </ng-container>
</ng-container>