import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbCarouselModule, NgbDatepickerModule, NgbDropdownModule, NgbTabsetModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
import { DiagramComponent } from '../mlm/member-tree/components/diagram/diagram.component';
import { InspectorComponent } from '../mlm/member-tree/components/inspector/inspector.component';
import { ProfileModule } from '../mlm/profile/profile.module';
import { AdminComponent } from '../theme/layout/admin/admin.component';
import { ConfigurationComponent } from '../theme/layout/admin/configuration/configuration.component';
import { NavBarComponent } from '../theme/layout/admin/nav-bar/nav-bar.component';
import { NavLeftComponent } from '../theme/layout/admin/nav-bar/nav-left/nav-left.component';
import { NavSearchComponent } from '../theme/layout/admin/nav-bar/nav-left/nav-search/nav-search.component';
import { NavRightComponent } from '../theme/layout/admin/nav-bar/nav-right/nav-right.component';
import { NavCollapseComponent } from '../theme/layout/admin/navigation/nav-content/nav-collapse/nav-collapse.component';
import { NavContentComponent } from '../theme/layout/admin/navigation/nav-content/nav-content.component';
import { NavGroupComponent } from '../theme/layout/admin/navigation/nav-content/nav-group/nav-group.component';
import { NavItemComponent } from '../theme/layout/admin/navigation/nav-content/nav-item/nav-item.component';
import { NavigationComponent } from '../theme/layout/admin/navigation/navigation.component';
import { ToggleFullScreenDirective } from '../theme/shared/full-screen/toggle-full-screen';
import { SharedModule } from '../theme/shared/shared.module';
import { DashboardComponent } from './dashboard/components/dashboard/dashboard.component';
import { MemberTreeComponent } from './member-tree/components/member-tree/member-tree.component';
import { MemberModule } from './member/member.module';
import { MlmRoutingModule } from './mlm-routing.module';
import { PurchaseModule } from './purchase/purchase.module';
import { WalletModule } from './wallet/wallet.module';
import { InventoryModule } from './inventory/inventory.module';
import { ReportModule } from './report/report.module';
import { UserDashboardComponent } from './dashboard/components/user-dashboard/user-dashboard.component';
import { AdminDashboardComponent } from './dashboard/components/admin-dashboard/admin-dashboard.component';
import { PayCommissionModule } from './pay-commission/pay-commission.module';
import { CommissionGenerateComponent } from './commission-generate/commission-generate.component';
import { TodaysJoiningComponent } from './dashboard/components/todays-joining/todays-joining.component';
import { TodaysActivationComponent } from './dashboard/components/todays-activation/todays-activation.component';
import { RegistrationComponent } from './registration/registration.component';
import { FranchisePurchaseModule } from '../mlm/franchise-purchase/franchise-purchase.module';
import { FranchiseModule } from '../mlm/franchise/franchise.module';
import { CnfModule } from '../mlm/cnf/cnf.module';
import { FranchiseDashboardComponent } from './dashboard/components/franchise-dashboard/franchise-dashboard.component';
import { RechargeModule } from '../mlm/recharge/recharge.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    NgbTabsetModule,
    NgbDropdownModule,
    NgbCarouselModule,
    MlmRoutingModule,
    NgbDatepickerModule,
    SharedModule,
    FormsModule,
    DataTablesModule,
    ReactiveFormsModule,
    ProfileModule,
    WalletModule,
    PurchaseModule,
    InventoryModule,
    MemberModule,
    ReportModule,
    PayCommissionModule,
    FranchisePurchaseModule,
    FranchiseModule,
    CnfModule,
    RechargeModule
  ],
  declarations: [
    DashboardComponent,
    MemberTreeComponent,
    AdminComponent,
    NavigationComponent,
    NavContentComponent,
    NavGroupComponent,
    NavCollapseComponent,
    NavItemComponent,
    NavBarComponent,
    NavLeftComponent,
    NavSearchComponent,
    NavRightComponent,
    ConfigurationComponent,
    ToggleFullScreenDirective,
    DiagramComponent,
    InspectorComponent,
    UserDashboardComponent,
    AdminDashboardComponent,
    CommissionGenerateComponent,
    TodaysJoiningComponent,
    TodaysActivationComponent,
    RegistrationComponent,
    FranchiseDashboardComponent
  ]
})
export class MLMModule { }
