import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { UserAuthService } from 'src/app/mlm/auth/services/user-auth.service';
import {
  DownlineReportFilter,
  DownlineReportsTableData,
} from '../../model/downline-reports-table.model';
import { DownlineService } from '../../services/downline.service';
import { ExcelExportService } from '../../services/excel-export.service';
import * as XLSX from 'xlsx';
import { MembersService } from 'src/app/mlm/member/services/members.service';
import { AllStates } from 'src/app/mlm/member/model/allstates.model';
import { CommonComponent } from 'src/app/theme/shared/common/common.component';

@Component({
  selector: 'app-downline-report',
  templateUrl: './downline-report.component.html',
  styleUrls: ['./downline-report.component.scss'],
})
export class DownlineReportComponent extends CommonComponent implements OnInit {
  fromDate: NgbDateStruct;
  toDate: NgbDateStruct;
  gridView: boolean = false;
  isAdmin: boolean = false;
  FilterFormGroup: FormGroup;
  status = ['All','Active','Inactive'];
  position = ['All','Left','Right'];
  columnsAdmin = [
    'Display ID',
    'Joining Date',
    'Name',
    'Phone Number',
    'Email',
    'Parent Name',
    'Sponser Name',
    'Position',
    // 'Is Active',
    'Is Green',
    'Green On',
    'State'
  ];
  columnsUser = [
    'Display ID',
    'Joining Date',
    'Name',
    'Parent Name',
    'Sponser Name',
    'Position',
    'Is Green',
    'Green On',
    'State'
  ];
  indexAdmin = [
    'displayId',
    'createdOn',
    'name',
    'phNo',
    'emailId',
    'parentName',
    'sponsorName',
    'parentIdWisePosition',
    // 'isActive',
    'isGreen',
    'greenOn',
    'stateName'
  ];
  indexUser = [
    'displayId',
    'createdOn',
    'name',
    'parentName',
    'sponsorName',
    'parentIdWisePosition',
    'isGreen',
    'greenOn',
    'stateName'
  ];
  downlineReportsTableData: DownlineReportsTableData[] = [];
  displayId: string;
  greenMember: number;
  leftMember: number;
  redMember: number;
  rightMember: number;
  totalMember: number;
  fileName = 'ExcelSheet.xlsx';
  loading: boolean = true;
  isDataAvailable: boolean;
  toJoiningDate: Date;
  fromJoiningDate: Date;
  allStatesData: AllStates[] = [];
  noData: boolean;

  constructor(
    private downlineService: DownlineService,
    private userAuthService: UserAuthService,
    public fb:FormBuilder,
    private excelExportService: ExcelExportService,
    private memberService: MembersService
  ) {
    super();
    this.FilterFormGroup = this.fb.group({
      displayId: new FormControl('', [Validators.required]),
      dojFrom: new FormControl('', [Validators.required]),
      dojTo: new FormControl('', [Validators.required]),
      greenFrom: new FormControl('', [Validators.required]),
      greenTo: new FormControl('', [Validators.required]),
      isGreen: new FormControl('', [Validators.required]),
      isRelativePositionLeft: new FormControl('', [Validators.required]),
      parentId: new FormControl('', [Validators.required]),
      sponsorId: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
    });
    this.isDataAvailable = this.downlineReportsTableData?.length > 0;
  }

  ngOnInit() {
    this.isAdmin = (this.userAuthService.getRoles() === 'ADMIN')?true:false;
    this.displayId = this.userAuthService.getDisplayId();
    this.toJoiningDate = new Date();
    this.fromJoiningDate =  new Date((new Date().getTime() - 585000000));
    this.memberService.getAllStatesData().subscribe((value) => {
      this.allStatesData = value.data;
      console.log(this.allStatesData)
    });
    this.firstShow();
  }

  firstShow(){
    const searchData = new DownlineReportFilter();
    searchData.displayId = this.FilterFormGroup.controls['displayId'].value?this.FilterFormGroup.controls['displayId'].value:this.displayId;
    searchData.dojTo = this.toJoiningDate;
    searchData.dojFrom = this.fromJoiningDate;
    searchData.greenFrom = this.FilterFormGroup.controls['greenFrom'].value;
    searchData.greenTo = this.FilterFormGroup.controls['greenTo'].value;
    this.allStatesData.forEach(element => {
      if(element.stateName == this.FilterFormGroup.controls['state'].value){
        searchData.stateCode = element.stateCode;
      }
    });
    if(!searchData.stateCode){
      searchData.stateCode = '';
    }
    if(this.FilterFormGroup.controls['isGreen'].value === 'All'){
      searchData.isGreen = null;
    }else if(this.FilterFormGroup.controls['isGreen'].value === 'Active'){
      searchData.isGreen = 'true';
    }else if(this.FilterFormGroup.controls['isGreen'].value === 'Inactive'){
      searchData.isGreen = 'false';
    }
    if(this.FilterFormGroup.controls['isRelativePositionLeft'].value === 'All'){
      searchData.isRelativePositionLeft = null;
    }else if(this.FilterFormGroup.controls['isRelativePositionLeft'].value === 'Left'){
      searchData.isRelativePositionLeft = 'true';
    }else if(this.FilterFormGroup.controls['isRelativePositionLeft'].value === 'Right'){
      searchData.isRelativePositionLeft = 'false';
    }
    searchData.parentId = this.FilterFormGroup.controls['parentId'].value;
    searchData.sponsorId = this.FilterFormGroup.controls['sponsorId'].value;
    if(this.FilterFormGroup.controls['dojFrom'].value<=this.FilterFormGroup.controls['dojTo'].value){
      if(this.FilterFormGroup.controls['greenFrom'].value<=this.FilterFormGroup.controls['greenTo'].value){
        this.downlineReportsTableData = [];
        this.gridView = false;
        this.downlineService
          .getDownlineTableData(searchData)
          .subscribe((value) => {
            this.downlineReportsTableData = value.data.downlineReports;
            this.isDataAvailable = this.downlineReportsTableData?.length > 0;
            this.noData = this.downlineReportsTableData?.length === 0;
            this.greenMember = value.data.greenMember;
            this.leftMember = value.data.leftMember;
            this.rightMember = value.data.rightMember;
            this.redMember = value.data.redMember;
            this.totalMember = value.data.totalMember;
            this.gridView = true;
          });
          this.loading = false;
      }else{
        this.dangerSwal("Please check Activation Date From or Activation Date To is wrong");
      }
    }else{
      this.dangerSwal("Please check Joining Date From or Joining Date To is wrong");
    }
  }

  search(){
    const searchData = new DownlineReportFilter();
    searchData.displayId = this.FilterFormGroup.controls['displayId'].value?this.FilterFormGroup.controls['displayId'].value:this.displayId;
    searchData.dojFrom = this.FilterFormGroup.controls['dojFrom'].value;
    searchData.dojTo = this.FilterFormGroup.controls['dojTo'].value;
    searchData.greenFrom = this.FilterFormGroup.controls['greenFrom'].value;
    searchData.greenTo = this.FilterFormGroup.controls['greenTo'].value;
    this.allStatesData.forEach(element => {
      if(element.stateName == this.FilterFormGroup.controls['state'].value){
        searchData.stateCode = element.stateCode;
      }
    });
    if(!searchData.stateCode){
      searchData.stateCode = '';
    }
    if(this.FilterFormGroup.controls['isGreen'].value === 'All'){
      searchData.isGreen = null;
    }else if(this.FilterFormGroup.controls['isGreen'].value === 'Active'){
      searchData.isGreen = 'true';
    }else if(this.FilterFormGroup.controls['isGreen'].value === 'Inactive'){
      searchData.isGreen = 'false';
    }
    if(this.FilterFormGroup.controls['isRelativePositionLeft'].value === 'All'){
      searchData.isRelativePositionLeft = null;
    }else if(this.FilterFormGroup.controls['isRelativePositionLeft'].value === 'Left'){
      searchData.isRelativePositionLeft = 'true';
    }else if(this.FilterFormGroup.controls['isRelativePositionLeft'].value === 'Right'){
      searchData.isRelativePositionLeft = 'false';
    }
    searchData.parentId = this.FilterFormGroup.controls['parentId'].value;
    searchData.sponsorId = this.FilterFormGroup.controls['sponsorId'].value;
    if(this.FilterFormGroup.controls['dojFrom'].value<=this.FilterFormGroup.controls['dojTo'].value){
      if(this.FilterFormGroup.controls['greenFrom'].value<=this.FilterFormGroup.controls['greenTo'].value){
        this.downlineReportsTableData = [];
        this.gridView = false;
        this.downlineService
          .getDownlineTableData(searchData)
          .subscribe((value) => {
            this.downlineReportsTableData = value.data.downlineReports;
            this.isDataAvailable = this.downlineReportsTableData?.length > 0;
            this.noData = this.downlineReportsTableData?.length === 0;
            this.greenMember = value.data.greenMember;
            this.leftMember = value.data.leftMember;
            this.rightMember = value.data.rightMember;
            this.redMember = value.data.redMember;
            this.totalMember = value.data.totalMember;
            this.gridView = true;
          });
          this.loading = false;
      }else{
        this.dangerSwal("Please check Activation Date From or Activation Date To is wrong");
      }
    }else{
      this.dangerSwal("Please check Joining Date From or Joining Date To is wrong");
    }

  }

  reset(){
    this.FilterFormGroup.reset();
    this.FilterFormGroup.controls['displayId'].setValue(this.displayId);
    this.FilterFormGroup.controls['state'].setValue('All');
    this.gridView = false;
  }

  exportexcel(): void
  {
    this.excelExportService.exportExcel(this.downlineReportsTableData, 'downline-report');
  }
}
