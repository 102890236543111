import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoggerService } from '../../../theme/shared/log/logger.service';
import { environment } from '../../../../environments/environment';
import { AbstractHttpService } from '../../../theme/shared/base/abstract-http';
import { FranchiseApproveReject, FranchiseRequest } from '../model/franchise.model';

@Injectable({
  providedIn: 'root'
})
export class FranchiseService extends AbstractHttpService {
  private _addfranchise : string = environment.baseUrl + "api/v1/addfranchise";
  private _checkcnfexist : string = environment.baseUrl + "api/v1/checkcnfexist?stateCode=";
  private _findinactivefranchise : string = environment.baseUrl + "api/v1/findinactivefranchise?type=4";
  private _adminapproval : string = environment.baseUrl + "api/v1/adminapproval";
  private _showactivefranchises : string = environment.baseUrl + "api/v1/showactivefranchises";
  private _getfranchisedetails : string = environment.baseUrl + "api/v1/getfranchisedetails";

  constructor(
    protected  http: HttpClient,
    protected  logger: LoggerService
    ) {
    super('FranchiseService', http, logger);
  };

  postAddFranchiseData(formData: FranchiseRequest) : Observable<any>{
    return this.http.post(this._addfranchise,formData);
  }

  getCheckCnfExist(stateCode: any) : Observable<any>{
    return this.http.get(this._checkcnfexist + stateCode);
  }

  getFindInactiveFranchise() : Observable<any>{
    return this.http.get(this._findinactivefranchise);
  }

  postAdminApproval(formData: FranchiseApproveReject) : Observable<any>{
    return this.http.post(this._adminapproval,formData);
  }

  getFranchisesData() : Observable<any>{
    return this.http.get(this._showactivefranchises);
  }

  getFranchiseDetailsData(franchiseId: string) : Observable<any>{
    const url = this._getfranchisedetails + `?franchiseId=${franchiseId}`
    return this.http.get(url);
  }
}
