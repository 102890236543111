import { Injectable } from '@angular/core';

export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}

const NavigationItems = [
  {
    id: 'navigation',
    title: 'Navigation',
    type: 'group',
    icon: 'feather icon-monitor',
    children: [
      {
      id: 'dashboard',
      title: 'Dashboard',
      type: 'item',
      icon: 'feather icon-home',
      url:  '/home/dashboard'
      },
      {
        id: 'profile',
        title: 'Profile',
        type: 'collapse',
        icon: 'feather icon-activity',
        children: [
          {
            id: 'view-profile',
            title: 'View Profile',
            type: 'item',
            url: '/home/profile/view-profile'
          },
          {
            id: 'update-profile',
            title: 'Update Profile',
            type: 'item',
            url: '/home/profile/update-profile'
          },
          {
            id: 'change-password',
            title: 'Change Password',
            type: 'item',
            url: '/home/profile/change-password'
          },
          {
            id: 'kyc-update',
            title: 'KYC Update',
            type: 'item',
            url: '/home/profile/kyc-update'
          },
          {
            id: 'kyc-verification',
            title: 'KYC Verification ',
            type: 'item',
            url: '/home/profile/kyc-verification'
          }
        ]
      },
      {
        id: 'members',
        title: 'Member',
        type: 'collapse',
        icon: 'feather icon-users',
        children: [
          {
            id: 'downline-member',
            title: 'Downline Member',
            type: 'item',
            url: '/home/member/downline-member'
          },
          {
            id: 'direct-member',
            title: 'Direct Member',
            type: 'item',
            url: '/home/member/direct-member'
          },
          {
            id: 'tree',
            title: 'Tree',
            type: 'item',
            url: '/home/member/tree'
          },
        ]
      },
      {
        id: 'wallet',
        title: 'Wallet',
        type: 'collapse',
        icon: 'feather icon-users',
        children: [
          {
            id: 'fund-add',
            title: 'Fund Add',
            type: 'item',
            url: '/home/wallet/fund-add'
          },
          {
            id: 'online-payment-report',
            title: 'Online Payment Report',
            type: 'item',
            url: '/home/wallet/online-payment-report'
          },
          {
            id: 'transaction-report',
            title: 'Transaction Report',
            type: 'item',
            url: '/home/wallet/transaction-report'
          },
          {
            id: 'fund-transfer-report',
            title: 'Fund Transfer Report',
            type: 'item',
            url: '/home/wallet/fund-transfer-report'
          },
          {
            id: 'fund-requests',
            title: 'Fund Requests',
            type: 'item',
            url: '/home/wallet/fund-requests'
          },
          {
            id: 'franchise-balance-requests',
            title: 'Franchise Balance Requests',
            type: 'item',
            url: '/home/wallet/franchise-balance-requests'
          },
          {
            id: 'balance-request-status',
            title: 'Balance Request Status',
            type: 'item',
            url: '/home/wallet/balance-request-status'
          },
          {
            id: 'member-wallet-balance',
            title: 'User Wallet Balance',
            type: 'item',
            url: '/home/wallet/member-wallet-balance' 
          }
        ]
      },
      {
        id: 'purchase',
        title: 'Purchase',
        type: 'collapse',
        icon: 'feather icon-users',
        children: [
          {
            id: 'shop',
            title: 'Shop',
            type: 'item',
            url: '/home/purchase/shop'
          },
          {
            id: 'cart',
            title: 'Cart',
            type: 'item',
            url: '/home/purchase/cart'
          },
          {
            id: 'orders',
            title: 'My Orders',
            type: 'item',
            url: '/home/purchase/orders'
          },
          {
            id: 'purchase-report',
            title: 'Purchase Report',
            type: 'item',
            url: '/home/purchase/purchase-report'
          },
          {
            id: 'purchase-delete',
            title: 'Purchase Delete',
            type: 'item',
            url: '/home/purchase/purchase-delete'
          }
        ]
      },
      {
        id: 'inventory',
        title: 'Inventory',
        type: 'collapse',
        icon: 'feather icon-users',
        children: [
          {
            id: 'item-master',
            title: 'Item Master',
            type: 'item',
            url: '/home/inventory/item-master'
          },
          {
            id: 'item-image',
            title: 'Item Image',
            type: 'item',
            url: '/home/inventory/item-image'
          },
          {
            id: 'stock-entry',
            title: 'Stock Entry',
            type: 'item',
            url: '/home/inventory/stock-entry'
          },
          {
            id: 'stock-search',
            title: 'Stock Search',
            type: 'item',
            url: '/home/inventory/stock-search'
          },
          {
            id: 'category-update',
            title: 'Update Category',
            type: 'item',
            url: '/home/inventory/category-update'
          },
          {
            id: 'update-item',
            title: 'Update Item',
            type: 'item',
            url: '/home/inventory/update-item'
          },
          // {
          //   id: 'sub-category-update',
          //   title: 'Update Sub Category',
          //   type: 'item',
          //   url: '/home/inventory/sub-category-update'
          // },
          // {
          //   id: 'product-update',
          //   title: 'Update Product',
          //   type: 'item',
          //   url: '/home/inventory/product-update'
          // },
        ]
      },
      // {
      //   id: 'first-purchase-commission-report',
      //   title: 'First Purchase',
      //   type: 'collapse',
      //   icon: 'feather icon-users',
      //   children: [
      //     {
      //       id: 'group-sales-income',
      //       title: 'Group Sales Income',
      //       type: 'item',
      //       url: '/home/first-purchase-commission-report/group-sales-income'
      //     },
      //     {
      //       id: 'sales-growth-income',
      //       title: 'Sales Growth Income',
      //       type: 'item',
      //       url: '/home/first-purchase-commission-report/sales-growth-income'
      //     },
      //     {
      //       id: 'royalty-fund-achievers',
      //       title: 'Royalty Fund Achievers',
      //       type: 'item',
      //       url: '/home/first-purchase-commission-report/royalty-fund-achievers'
      //     },
      //     {
      //       id: 'travel-fund-achievers',
      //       title: 'Travel Fund Achievers',
      //       type: 'item',
      //       url: '/home/first-purchase-commission-report/travel-fund-achievers'
      //     },
      //     {
      //       id: 'car-fund-achievers',
      //       title: 'Car Fund Achievers',
      //       type: 'item',
      //       url: '/home/first-purchase-commission-report/car-fund-achievers'
      //     },
      //     {
      //       id: 'house-fund-achievers',
      //       title: 'House Fund Achievers',
      //       type: 'item',
      //       url: '/home/first-purchase-commission-report/house-fund-achievers'
      //     },
      //     {
      //       id: 'sales-incentives',
      //       title: 'Sales Incentives',
      //       type: 'item',
      //       url: '/home/first-purchase-commission-report/sales-incentives'
      //     },
      //     {
      //       id: 'others',
      //       title: 'Others',
      //       type: 'item',
      //       url: '/home/first-purchase-commission-report/others'
      //     },
      //   ]
      // },
      // {
      //   id: 're-purchase-commission-report',
      //   title: 'Re-purchase',
      //   type: 'collapse',
      //   icon: 'feather icon-users',
      //   children: [
      //     {
      //       id: 'repurchase-group-sales-income',
      //       title: 'Re-purchase Group Sales Income',
      //       type: 'item',
      //       url: '/home/re-purchase-commission-report/re-purchase-group-sales-income'
      //     },
      //     {
      //       id: 're-purchase-accumulative-income',
      //       title: 'Re-purchase Accumulative Income',
      //       type: 'item',
      //       url: '/home/re-purchase-commission-report/re-purchase-accumulative-income'
      //     },
      //     {
      //       id: 'achievement-pool-bonus',
      //       title: 'Achievement Pool Bonus',
      //       type: 'item',
      //       url: '/home/re-purchase-commission-report/achievement-pool-bonus'
      //     },
      //     {
      //       id: 'brand-ambassador-income',
      //       title: 'Brand Ambassador Income',
      //       type: 'item',
      //       url: '/home/re-purchase-commission-report/brand-ambassador-income'
      //     },
      //     {
      //       id: 'rewards',
      //       title: 'Rewards',
      //       type: 'item',
      //       url: '/home/re-purchase-commission-report/rewards'
      //     },
      //     {
      //       id: 'travel-fund',
      //       title: 'Travel Fund',
      //       type: 'item',
      //       url: '/home/re-purchase-commission-report/travel-fund'
      //     },
      //     {
      //       id: 'repurchase-group-sales-income',
      //       title: 'Repurchase Group Sales Income',
      //       type: 'item',
      //       url: '/home/re-purchase-commission-report/repurchase-group-sales-income'
      //     },
      //     {
      //       id: 'car-fund',
      //       title: 'Car Fund',
      //       type: 'item',
      //       url: '/home/re-purchase-commission-report/car-fund'
      //     },
      //   ]
      // },
      {
        id: 'commission-generate',
        title: 'Commission Generate',
        type: 'item',
        icon: 'feather icon-activity',
        url:  '/home/commission-generate',
        // hidden: (localStorage.getItem('roles')==='ADMIN')?false:true
      },
      // {
      //   id: 'total-commission',
      //   title: 'Total Commission',
      //   type: 'collapse',
      //   icon: 'feather icon-activity',
      //   children: [
      //     {
      //       id: 'paid-commission-report',
      //       title: 'Paid Commission Report',
      //       type: 'item',
      //       url: '/home/total-commission/paid-commission-report'
      //     },
      //     {
      //       id: 'unpaid-commission-report',
      //       title: 'Unpaid Commission Report',
      //       type: 'item',
      //       url: '/home/total-commission/unpaid-commission-report'
      //     }
      //   ]
      // },
      {
        id: 'pay-commission',
        title: 'Pay Commission',
        type: 'collapse',
        icon: 'feather icon-activity',
        children: [
          {
            id: 'unpaid-commission',
            title: 'Unpaid Commission',
            type: 'item',
            url: '/home/view-commission/unpaid-commission'
          },
          {
            id: 'paid-commission',
            title: 'Paid Commission',
            type: 'item',
            url: '/home/view-commission/paid-commission'
          },
          {
            id: 'bank-list',
            title: 'Bank List',
            type: 'item',
            url: '/home/view-commission/bank-list'
          },
          {
            id: 'wallet-report',
            title: 'Wallet Report',
            type: 'item',
            url: '/home/view-commission/wallet-report'
          },
          {
            id: 'monthwise-commission',
            title: 'Monthwise Commission',
            type: 'item',
            url: '/home/view-commission/monthwise-commission'
          },
          {
            id: 'all-commissions',
            title: 'All Commissions',
            type: 'item',
            url: '/home/view-commission/all-commissions'
          },
        ]
      },
      {
        id: 'report',
        title: 'Report',
        type: 'collapse',
        icon: 'feather icon-activity',
        children: [
          {
            id: 'commissions-tds', 
            title: 'Monthwise TDS Report',
            type: 'item',
            url: '/home/report/commissions-tds'
          },
          {
            id: 'downline-report',
            title: 'Downline Report',
            type: 'item',
            url: '/home/report/downline-report'
          },
          {
            id: 'bi-module',
            title: 'BI Module',
            type: 'item',
            url: '/home/report/bi-module'
          },
          {
            id: 'repurchase-report',
            title: 'Repurchase Report',
            type: 'item',
            url: '/home/report/repurchase-report'
          },
          {
            id: 'gst-monthwise',
            title: 'Monthwise GST',
            type: 'item',
            url: '/home/report/gst-monthwise'
          },
          {
            id: 'gst-statewise',
            title: 'Statewise GST',
            type: 'item',
            url: '/home/report/gst-statewise'
          },
          {
            id: 'firstpurchase-rank',
            title: 'First Purchase Ranks',
            type: 'item',
            url: '/home/report/firstpurchase-rank'
          },
          {
            id: 'view-commission',
            title: 'View Commission',
            type: 'item',
            url: '/home/report/view-commission'
          },
          {
            id: 'payment-status-report',
            title: 'Payment Status Report',
            type: 'item',
            url: '/home/report/payment-status-report'
          },
          {
            id: 'tds-report',
            title: 'TDS Report',
            type: 'item',
            url: '/home/report/tds-report'
          },
          {
            id: 'view-offers',
            title: 'View Offers',
            type: 'item',
            url: '/home/report/view-offers'
          }
        ]
      },
      {
        id: 'tds-report',
        title: 'TDS Report',
        type: 'collapse',
        icon: 'feather icon-activity',
        children: [
          // {
          //   id: 'extra',
          //   title: 'Extra',
          //   type: 'item',
          //   url: '/home/tds-report/extra'
          // },
          {
            id: 'support-ticket',
            title: 'Support Ticket',
            type: 'item',
            url: '/home/tds-report/support-ticket'
          },
          {
            id: 'notification',
            title: 'Notification',
            type: 'item',
            url: '/home/tds-report/notification'
          },
          {
            id: 'welcome-later',
            title: 'Welcome Later',
            type: 'item',
            url: '/home/tds-report/welcome-later'
          },
          {
            id: 'id-card',
            title: 'I’d card',
            type: 'item',
            url: '/home/tds-report/id-card'
          },
        ]
      },
      {
        id: 'franchise',
        title: 'Franchise',
        type: 'collapse',
        icon: 'feather icon-activity',
        children: [
          {
            id: 'franchise-request',
            title: 'Franchise Request',
            type: 'item',
            url: '/home/franchise/franchise-request'
          },
          {
            id: 'franchise-request-status',
            title: 'Franchise Request Status',
            type: 'item',
            url: '/home/franchise/franchise-request-status'
          },
          {
            id: 'franchise-request-approval',
            title: 'Franchise Request Approval',
            type: 'item',
            url: '/home/franchise/franchise-request-approval'
          },
          {
            id: 'franchise-members',
            title: 'Franchise Members',
            type: 'item',
            url: '/home/franchise/franchise-members'
          }
        ]
      },
      {
        id: 'cnf',
        title: 'CNF',
        type: 'collapse',
        icon: 'feather icon-activity',
        children: [
          {
            id: 'add-cnf',
            title: 'Add CNF',
            type: 'item',
            url: '/home/cnf/add-cnf'
          }
        ]
      },
      {
        id: 'franchise-purchase',
        title: 'Purchase',
        type: 'collapse',
        icon: 'feather icon-activity',
        children: [
          {
            id: 'purchase',
            title: 'Downline Purchase',
            type: 'item',
            url: '/home/franchise-purchase/purchase'
          },
          {
            id: 'multi-purchase',
            title: 'Multi Purchase',
            type: 'item',
            url: '/home/franchise-purchase/multi-purchase'
          },
          {
            id: 'purchase-report',
            title: 'Purchase Report',
            type: 'item',
            url: '/home/franchise-purchase/purchase-report'
          }
        ]
      },
      {
        id: 'recharge',
        title: 'Recharge',
        type: 'collapse',
        icon: 'feather icon-activity',
        children: [
          {
            id: 'recharge',
            title: 'Recharge',
            type: 'item',
            url: '/home/recharge/recharge'
          },
          {
            id: 'product-master',
            title: 'Product Master',
            type: 'item',
            url: '/home/recharge/product-master'
          },
          {
            id: 'transaction-enquiry',
            title: 'Transaction Enquiry',
            type: 'item',
            url: '/home/recharge/transaction-enquiry'
          },
          {
            id: 'view-balance',
            title: 'View Balance',
            type: 'item',
            url: '/home/recharge/view-balance'
          },
          {
            id: 'recharge-report',
            title: 'Recharge Report',
            type: 'item',
            url: '/home/recharge/recharge-report'
          }
        ]
      },
    ]
  }
]

@Injectable()
export class NavigationItem {
  public get() {
    return NavigationItems;
  }
}
