import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import * as go from 'gojs';
import { UserAuthService } from 'src/app/mlm/auth/services/user-auth.service';
import { CommonComponent } from 'src/app/theme/shared/common/common.component';

const $ = go.GraphObject.make;

@Component({
  selector: 'app-diagram',
  templateUrl: './diagram.component.html',
  styleUrls: ['./diagram.component.css'],
})
export class DiagramComponent extends CommonComponent implements OnInit {
  public diagram: go.Diagram = null;
  isAdmin: boolean = false;
  @Input()
  public model: go.Model;

  @Output()
  public nodeClicked = new EventEmitter();

  constructor(private userAuthService: UserAuthService) {
    super();
  }

  ngOnInit(): void {
    this.isAdmin = this.userAuthService.getRoles() === 'ADMIN' ? true : false;
  }

  public ngAfterViewInit() {
    this.diagram = $(go.Diagram, 'myDiagramDiv', {
      layout: $(go.TreeLayout, {
        isOngoing: true,
        treeStyle: go.TreeLayout.StyleLastParents,
        arrangement: go.TreeLayout.ArrangementHorizontal,
        // properties for most of the tree:
        angle: 90,
        layerSpacing: 35,
        // properties for the "last parents":
        alternateAngle: 90,
        alternateLayerSpacing: 35,
        alternateAlignment: go.TreeLayout.AlignmentBus,
        alternateNodeSpacing: 20,
      }),
      'undoManager.isEnabled': true,
      initialContentAlignment: go.Spot.TopCenter,
      'animationManager.isEnabled': true, // Disable animations
      'allowZoom': true, // Disable zooming
      'allowSelect': true, // Disable node selection
      'allowMove': false, // Disable node dragging
      'allowHorizontalScroll': true, // Disable horizontal scrolling
      'allowVerticalScroll': true 
    });

    // define the Node template
    this.diagram.nodeTemplate = $(
      go.Node,
      'Auto',
      // for sorting, have the Node.text be the data.name
      new go.Binding('text', 'displayId'),
      // bind the Part.layerName to control the Node's layer depending on whether it isSelected
      new go.Binding('layerName', 'isSelected', function (sel) {
        return sel ? 'Foreground' : '';
      }).ofObject(),
      // define the node's outer shape
      $(
        go.Shape,
        'RoundedRectangle',
        {
          name: 'SHAPE',
          fill: 'lightblue',
          stroke: 'gray',
          strokeWidth: 1 ,
          // set the port properties:
          portId: '',
          fromLinkable: false,
          toLinkable: false,
          cursor: 'pointer',
        },
        new go.Binding('fill', '', function (node) {
          // modify the fill based on the tree depth level
          // const levelColors = [
            // '#AC193D',
            // '#008A00',

            // '#2672EC',
            // '#8C0095',
            // '#5133AB',
            // '#008299',
            // '#D24726',
            // '#094AB2',

            // '#000000'
          // ];
          // let color = node.findObject('SHAPE').fill;
          // const dia: go.Diagram = node.diagram;
          // if (dia && dia.layout.network) {
          //   dia.layout.network.vertexes.each(function (v: go.TreeVertex) {
          //     if (v.node && v.node.key === node.data.key) {
          //       // const level: number = v.level % levelColors.length;
          //       // const isGreen: boolean = node.data.isGreen;
          //       if(node.data.isGreen === true){
          //         color = '#008A00';
          //       }else if(node.data.isGreen === false){
          //         color = '#AC193D';
          //       }
          //     }
          //   });
          // }
            return node.data.isGreen?'#008A00':'#AC193D';
        }).ofObject()
      ),
      $(
        go.Panel,
        'Horizontal',
        // $(
        //   go.Picture,
        //   {
        //     name: 'Picture',
        //     desiredSize: new go.Size(39, 50),
        //     margin: new go.Margin(6, 8, 6, 10),
        //   },
        //   new go.Binding('source', 'key', function (key) {
        //     return 'assets/images/user/avatar-sample.jpg';
        //   })
        // ),
        // define the panel where the text will appear
        $(
          go.Panel,
          'Table',
          {
            maxSize: new go.Size(200, 999),
            margin: new go.Margin(6, 10, 0, 3),
            defaultAlignment: go.Spot.TopCenter,
          },
          $(go.RowColumnDefinition, { column: 2, width: 4 }),
          $(
            go.TextBlock,
            { font: '9pt  Segoe UI,sans-serif', stroke: 'white' }, // the name
            {
              row: 0,
              column: 0,
              columnSpan: 5,
              font: '12pt Segoe UI,sans-serif',
              editable: false,
              isMultiline: false,
              minSize: new go.Size(10, 16),
            },
            new go.Binding('text', 'name').makeTwoWay()
          ),
          $(
            go.TextBlock,
            'Display Id: ',
            { font: '9pt  Segoe UI,sans-serif', stroke: 'white' },
            { row: 1, column: 0 }
          ),
          $(
            go.TextBlock,
            { font: '9pt  Segoe UI,sans-serif', stroke: 'white' }, // the name
            {
              row: 1,
              column: 1,
              columnSpan: 4,
              editable: false,
              isMultiline: false,
              minSize: new go.Size(10, 14),
              margin: new go.Margin(0, 0, 0, 3),
            },
            new go.Binding('text', 'displayId').makeTwoWay()
          ),
          $(
            go.TextBlock,
            'Sponsor Id: ',
            { font: '9pt  Segoe UI,sans-serif', stroke: 'white' },
            { row: 2, column: 0 }
          ),
          $(
            go.TextBlock,
            { font: '9pt  Segoe UI,sans-serif', stroke: 'white' }, // the name
            {
              row: 2,
              column: 1,
              columnSpan: 4,
              editable: false,
              isMultiline: false,
              minSize: new go.Size(10, 14),
              margin: new go.Margin(0, 0, 0, 3),
            },
            new go.Binding('text', 'parentId').makeTwoWay()
          ),
          $(
            go.TextBlock,
            'Joining date: ',
            { font: '9pt  Segoe UI,sans-serif', stroke: 'white' },
            { row: 3, column: 0 }
          ),
          $(
            go.TextBlock,
            { font: '9pt  Segoe UI,sans-serif', stroke: 'white' }, // the name
            {
              row: 3,
              column: 1,
              columnSpan: 4,
              editable: false,
              isMultiline: false,
              minSize: new go.Size(10, 14),
              margin: new go.Margin(0, 0, 0, 3),
            },
            new go.Binding('text', 'doj').makeTwoWay()
          ),
          $(
            go.TextBlock,
            'Activation date: ',
            { font: '9pt  Segoe UI,sans-serif', stroke: 'white' },
            { row: 4, column: 0 }
          ),
          $(
            go.TextBlock,
            { font: '9pt  Segoe UI,sans-serif', stroke: 'white' }, // the name
            {
              row: 4,
              column: 1,
              columnSpan: 4,
              editable: false,
              isMultiline: false,
              minSize: new go.Size(10, 14),
              margin: new go.Margin(0, 0, 0, 3),
            },
            new go.Binding('text', 'greenOn').makeTwoWay()
          ),
          // $(
          //   go.TextBlock,
          //   'Is Green: ',
          //   { font: '9pt  Segoe UI,sans-serif', stroke: 'white' },
          //   { row: 2, column: 0 }
          // ),
          // $(
          //   go.TextBlock,
          //   { font: '9pt  Segoe UI,sans-serif', stroke: 'white' }, // the name
          //   {
          //     row: 2,
          //     column: 1,
          //     columnSpan: 3,
          //     editable: false,
          //     isMultiline: false,
          //     minSize: new go.Size(10, 14),
          //     margin: new go.Margin(0, 0, 0, 3),
          //   },
          //   new go.Binding('text', 'isGreen').makeTwoWay()
          // ),
          $(
            go.TextBlock,
            'Position: ',
            { font: '9pt  Segoe UI,sans-serif', stroke: 'white' },
            { row: 5, column: 0 }
          ),
          $(
            go.TextBlock,
            { font: '9pt  Segoe UI,sans-serif', stroke: 'white' }, // the name
            {
              row: 5,
              column: 1,
              columnSpan: 4,
              editable: false,
              isMultiline: false,
              minSize: new go.Size(10, 14),
              margin: new go.Margin(0, 0, 0, 3),
            },
            new go.Binding('text', 'position').makeTwoWay()
          ),
          this.isAdmin
            ? $(
                go.TextBlock,
                'Wallet Balance: ',
                { font: '9pt  Segoe UI,sans-serif', stroke: 'white' },
                { row: 6, column: 0 }
              )
            : '',
          this.isAdmin
            ? $(
                go.TextBlock,
                { font: '9pt  Segoe UI,sans-serif', stroke: 'white' },
                {
                  row: 6,
                  column: 1,
                  columnSpan: 4,
                  editable: false,
                  isMultiline: false,
                  minSize: new go.Size(10, 14),
                  margin: new go.Margin(0, 0, 0, 3),
                },
                new go.Binding('text', 'walletBalance').makeTwoWay()
              )
            : '',
          // $(
          //   go.TextBlock,
          //   { font: '9pt  Segoe UI,sans-serif', stroke: 'white' },
          //   { row: 3, column: 0 },
          //   new go.Binding('text', 'key', function (v) {
          //     return 'ID: ' + v;
          //   })
          // ),
          // $(
          //   go.TextBlock,
          //   { font: '9pt  Segoe UI,sans-serif', stroke: 'white' },
          //   { name: 'boss', row: 3, column: 3 }, // we include a name so we can access this TextBlock when deleting Nodes/Links
          //   new go.Binding('text', 'parent', function (v) {
          //     return 'Boss: ' + v;
          //   })
          // ),

          // $(
          //   go.TextBlock,
          //   { font: '9pt  Segoe UI,sans-serif', stroke: 'white' }, // the comments
          //   {
          //     row: 5,
          //     column: 0,
          //     columnSpan: 4,
          //     font: 'italic 9pt sans-serif',
          //     wrap: go.TextBlock.WrapFit,
          //     editable: false, // by default newlines are allowed
          //     minSize: new go.Size(10, 14),
          //   },
          //   new go.Binding('text', 'comments').makeTwoWay()
          // ),

        ) // end Table Panel
      ) // end Horizontal Panel
    ); // end Node

    this.diagram.model = this.model;

    // when the selection changes, emit event to app-component updating the selected node
    this.diagram.addDiagramListener('ChangedSelection', (e) => {
      const node = this.diagram.selection.first();
      console.log(node)
      this.nodeClicked.emit(node);
    });
    const zoomFactor = 0.7; // You can adjust this factor as needed
    this.diagram.commandHandler.decreaseZoom(zoomFactor);
  }

  zoomOut(){
     this.diagram.commandHandler.zoomToFit();
  }

  zoomCenter(){
    this.diagram.scale = 1;
    this.diagram.commandHandler.scrollToPart(this.diagram.findNodeForKey(1));
  }
}
