import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserAuthService } from 'src/app/mlm/auth/services/user-auth.service';
import { AllStates } from 'src/app/mlm/member/model/allstates.model';
import { MembersService } from 'src/app/mlm/member/services/members.service';
import { UpdateProfile } from '../../model/profile.model';
import { ProfileService } from '../../services/profile.service';
import { DetailsDisplayId } from '../../../member/model/details-displayid.model';
import { CommonComponent } from 'src/app/theme/shared/common/common.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-update-profile',
  templateUrl: './update-profile.component.html',
  styleUrls: ['./update-profile.component.scss']
})
export class UpdateProfileComponent extends CommonComponent implements OnInit {
  UpdateProfileGroup: FormGroup;
  allStatesData: AllStates[] = [];
  userInformations: DetailsDisplayId;
  stateNameHold : any;
  isAdmin: boolean = false;
  accountHolderNameExists: boolean = false;
  accountNumberExists: boolean = false;
  bankNameExists: boolean = false;
  ifscCodeExists: boolean = false;
  formShow: boolean;


  constructor(
    public fb:FormBuilder,
    private memberService: MembersService,
    private profileService: ProfileService,
    private userAuthService: UserAuthService,
    private router: Router
  )
  {
    super();
    this.UpdateProfileGroup = this.fb.group({
      name: new FormControl('', [Validators.required]),
      gender: new FormControl('', [Validators.required]),
      dob: new FormControl('', [Validators.required]),
      maritalStatus: new FormControl('', [Validators.required]),
      cityVillage: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      pincode: new FormControl('', [Validators.required]),
      pan: new FormControl('', [Validators.required]),
      aadhaar: new FormControl('', [Validators.required]),
      accountHolderName: new FormControl('', [Validators.required]),
      accountNo: new FormControl('', [Validators.required]),
      bankName: new FormControl('', [Validators.required]),
      ifsc: new FormControl('', [Validators.required]),
      guardian: new FormControl('', [Validators.required]),
      nominee: new FormControl('', [Validators.required]),
      nomineeRelation: new FormControl('', [Validators.required]),
      nomineeDob: new FormControl('', [Validators.required]),
      phNo: new FormControl('', [Validators.required]),
    });

    this.userInformations = new DetailsDisplayId();
   }

  ngOnInit(): void {

    this.memberService.getAllStatesData().subscribe((value) => {
      this.allStatesData = value.data;
      console.log(this.allStatesData)
    });

    this.memberService.getverifyMemberInfoDisplayId(parseInt(this.userAuthService.getDisplayId())).subscribe((val)=>{
      if(val){
        this.userInformations = val.data;
        this.accountHolderNameExists = val.data.accountHolderName?true:false;
        this.accountNumberExists = val.data.accountNo?true:false;
        this.bankNameExists = val.data.bankName?true:false;
        this.ifscCodeExists = val.data.ifsc?true:false;
        setTimeout(() => {
          this.allStatesData.forEach(element => {
            if(element.stateCode == this.userInformations.stateCode){
              this.stateNameHold =  element.stateName;
            }
          });
        }, 1000);
      }
    })

    this.isAdmin = this.userAuthService.getRoles()==='ADMIN'?true:false;

  }

  submitUpdate(){
      this.allStatesData.forEach(element => {
        if(element.stateName == this.UpdateProfileGroup.controls['state'].value){
          const updateProfile = new UpdateProfile();
          updateProfile.stateCode = element.stateCode;
          updateProfile.displayId = parseInt(this.userAuthService.getDisplayId());
          updateProfile.name = this.UpdateProfileGroup.controls['name'].value;
          updateProfile.gender = this.UpdateProfileGroup.controls['gender'].value;
          updateProfile.dob = this.UpdateProfileGroup.controls['dob'].value;
          updateProfile.maritalStatus = this.UpdateProfileGroup.controls['maritalStatus'].value;
          updateProfile.cityVillage = this.UpdateProfileGroup.controls['cityVillage'].value;
          updateProfile.address = this.UpdateProfileGroup.controls['address'].value;
          updateProfile.pinCode = this.UpdateProfileGroup.controls['pincode'].value;
          updateProfile.panNo = this.UpdateProfileGroup.controls['pan'].value;
          updateProfile.aadharNo = this.UpdateProfileGroup.controls['aadhaar'].value;
          updateProfile.accountHolderName = this.UpdateProfileGroup.controls['accountHolderName'].value;
          updateProfile.accountNo = this.UpdateProfileGroup.controls['accountNo'].value;
          updateProfile.bankName = this.UpdateProfileGroup.controls['bankName'].value;
          updateProfile.ifsc = this.UpdateProfileGroup.controls['ifsc'].value;
          updateProfile.gurdian = this.UpdateProfileGroup.controls['guardian'].value;
          updateProfile.nominee = this.UpdateProfileGroup.controls['nominee'].value;
          updateProfile.relation = this.UpdateProfileGroup.controls['nomineeRelation'].value;
          updateProfile.nomineeDob = this.UpdateProfileGroup.controls['nomineeDob'].value;
          updateProfile.phNo = this.UpdateProfileGroup.controls['phNo'].value;
          this.profileService.postProfileUpdateData(updateProfile).subscribe((val)=>{
            if(val.message==null){
              this.successSwal("Profile Updated Successfully");
              // this.memberService.getverifyMemberInfoDisplayId(parseInt(this.userAuthService.getDisplayId())).subscribe((val)=>{
              //   if(val){
              //     this.userInformations = val.data;
              //     console.log(this.userInformations);
              //     this.allStatesData.forEach(element => {
              //       if(element.stateCode == this.userInformations.stateCode){
              //         this.stateNameHold =  element.stateName;
              //       }
              //     });
              //   }
              // })
              this.ngOnInit();
            }else{
              this.dangerSwal(val.message);
            }
          })
        }
      });
  }

  cancel(){
    this.memberService.getverifyMemberInfoDisplayId(parseInt(this.userAuthService.getDisplayId())).subscribe((val)=>{
      if(val){
        this.userInformations = val.data;
        console.log(this.userInformations);
      }
    })
  }

}
