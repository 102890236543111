export class DetailsDisplayId {
  id: number;
  displayId: number;
  name: string;
  userName: string;
  phNo: number;
  isActive: number;
  createdOn: string;
  pinCode: string;
  stateCode: string;
  dob: Date;
  password: string;
  isleft: boolean;
  emailId: string;
  aadharNo: number;
  panNo: string;
  bankName: string;
  ifsc: string;
  accountHolderName: string;
  accountNo: string;
  sponsorID: number;
  parentID: number;
  gender: string;
  gurdian: string;
  maritalStatus: string;
  cityVillage: string;
  address: string;
  nominee: string;
  relation: string;
  nomineeDob: Date;
  isGreen: boolean;
  type: number;
  positionVacant: string;
  positionEligible: string;
  childCount: number;
  validDate: string;
}

export class DownlineSubmit{
  displayId: string;
  viewUpline: boolean;
  viewDownline: boolean;
}
