import { DiagramComponent } from './../diagram/diagram.component';
import { Component, OnInit } from '@angular/core';
import * as go from 'gojs';
import { MemberTreeService } from '../../services/member-tree.service';
import { UserAuthService } from 'src/app/mlm/auth/services/user-auth.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MemberTree } from '../../model/member-tree.model';
import { CommonComponent } from 'src/app/theme/shared/common/common.component';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { DetailsDisplayId } from 'src/app/mlm/member/model/details-displayid.model';
@Component({
  selector: 'app-member-tree',
  templateUrl: './member-tree.component.html',
  styleUrls: ['./member-tree.component.scss']
})
export class MemberTreeComponent extends CommonComponent implements OnInit {
  public displayidmap: Map<number, number> = new Map();
  public currentDisplayId: number;
  public selectedNode = null;
  public query:string;
  public searchResults: DetailsDisplayId[];
  public model: go.TreeModel;
  displayId: string;
  searchTreeGroup: FormGroup;
  treeData: MemberTree[] = [];
  highestKey : number = 0;
  constructor(
    private memberTreeData: MemberTreeService,
    private userAuthService: UserAuthService,
    private fb: FormBuilder
  ){
    super();
    this.model = new go.TreeModel();
    this.searchTreeGroup = this.fb.group({
      displayId: new FormControl('', [Validators.required]),
    });
  }

  public setSelectedNode(node: any) {
    this.selectedNode = node;
  }

  ngOnInit(): void {
    this.displayId = this.userAuthService.getDisplayId();
    this.currentDisplayId = parseInt(this.displayId);
    this.displayidmap.set(this.currentDisplayId, this.currentDisplayId);
    this.memberTreeData.getMemberTreeData(parseInt(this.displayId),0,0).subscribe(val =>{
      if(val){
        console.log(val.data);
        val.data.forEach(element => {
          if(element.key>this.highestKey){
            this.highestKey = element.key;
          }
        });
        this.treeData = val.data;
        for (let i = 0; i < this.treeData.length; i++) {
          if (this.treeData[i]) {
            if (this.treeData[i].doj) {
              this.treeData[i].doj = this.treeData[i].doj.substring(0, 10);
            }
            if (this.treeData[i].greenOn) {
              this.treeData[i].greenOn = this.treeData[i].greenOn.substring(0, 10);
            }
          }
        }
        this.model.mergeNodeDataArray(this.treeData);
        console.log(this.model)
      }
    })
  }

  goBack() {
    if(this.displayidmap.get(this.currentDisplayId)){
      this.memberTreeData.getMemberTreeData(this.displayidmap.get(this.currentDisplayId),0,0).subscribe(val =>{
        if(val.data){
          console.log(val.data);
          val.data.forEach(element => {
            if(element.key>this.highestKey){
              this.highestKey = element.key;
            }
          });
          this.treeData = val.data;
          this.model.mergeNodeDataArray(this.treeData);
          this.model.mergeNodeDataArray(this.treeData);
          this.setSelectedNode('');
          console.log(this.model)
        }else{
          this.dangerSwal('No data found');
        }
        this.currentDisplayId = this.displayidmap.get(this.currentDisplayId);
      })
    }else{
      this.dangerSwal("Please try after some time.");
    }
  }

  newTreeGenerate(event: MemberTree){
    
    this.displayidmap.set(event.displayId, this.currentDisplayId);
    this.currentDisplayId = event.displayId;
    // console.log('click tree',event);
    // if(event.level == 4 && !event.clicked){
    //   this.treeData.forEach(element => {
    //     if(element.key == event.key){
    //       element.clicked = true;
    //     }
    //   });
    //   this.memberTreeData.getMemberTreeData(event.displayId,this.highestKey,event.key).subscribe(val =>{
    //     if(val){
    //       console.log(val.data);
    //       val.data.forEach(element => {
    //         if(element.key>this.highestKey){
    //           this.highestKey = element.key;
    //         }
    //         this.treeData.push(element);
    //       });
    //       this.model.mergeNodeDataArray(this.treeData);
    //       console.log(this.model)
    //     }
    //   })
    // }
    this.memberTreeData.getMemberTreeData(event.displayId,0,0).subscribe(val =>{
      if(val.data){
        console.log(val.data);
        val.data.forEach(element => {
          if(element.key>this.highestKey){
            this.highestKey = element.key;
          }
        });
        this.treeData = val.data;
        
        for (let i = 0; i < this.treeData.length; i++) {
          if (this.treeData[i]) {
            if (this.treeData[i].doj) {
              this.treeData[i].doj = this.treeData[i].doj.substring(0, 10);
            }
            if (this.treeData[i].greenOn) {
              this.treeData[i].greenOn = this.treeData[i].greenOn.substring(0, 10);
            }
          }
        }

        this.model.mergeNodeDataArray(this.treeData);
      }else{
        this.dangerSwal('No data found');
      }
    })
  }

  onKeyUp() {
    if (this.searchTreeGroup.controls['displayId'].value && this.searchTreeGroup.controls['displayId'].value.length >=4) {
      this.memberTreeData.searchMembers(this.searchTreeGroup.controls['displayId'].value)
      .pipe(
        debounceTime(300), // wait for 300ms pause in events
        distinctUntilChanged(), // only proceed if the query has changed
        switchMap(() => this.memberTreeData.searchMembers(this.searchTreeGroup.controls['displayId'].value))
      )
      .subscribe(val => {
        if (val.data && val.data.length > 0) {
          this.searchResults = val.data;
        } else {
          this.dangerSwal('No results found');
        }
      });
    } else {
      this.dangerSwal('Search text is too short. Enter atleast 4 characters');
    }
  }

  submitDisplayId(){
    let disId: number = parseInt(this.searchTreeGroup.controls['displayId'].value);
    this.searchResults = null;
    if (disId) {
      this.memberTreeData.getMemberTreeData(parseInt(this.searchTreeGroup.controls['displayId'].value),0,0).subscribe(val =>{
        if(val.data){
          console.log(val.data);
          val.data.forEach(element => {
            if(element.key>this.highestKey){
              this.highestKey = element.key;
            }
          });
          this.treeData = val.data;
          for (let i = 0; i < this.treeData.length; i++) {
            if (this.treeData[i]) {
              if (this.treeData[i].doj) {
                this.treeData[i].doj = this.treeData[i].doj.substring(0, 10);
              }
              if (this.treeData[i].greenOn) {
                this.treeData[i].greenOn = this.treeData[i].greenOn.substring(0, 10);
              }
            }
          }
          this.model.mergeNodeDataArray(this.treeData);
          this.model.mergeNodeDataArray(this.treeData);
          this.setSelectedNode('');
          console.log(this.model)
        }else{
          this.dangerSwal('No data found');
        }
      })
    }else{
      this.onKeyUp();
    }
  }

  submitSearchDisplayId(displayId: string){
    if(displayId){
      this.memberTreeData.getMemberTreeData(parseInt(displayId),0,0).subscribe(val =>{
        if(val.data){
          console.log(val.data);
          val.data.forEach(element => {
            if(element.key>this.highestKey){
              this.highestKey = element.key;
            }
          });
          this.treeData = val.data;
          for (let i = 0; i < this.treeData.length; i++) {
            if (this.treeData[i]) {
              if (this.treeData[i].doj) {
                this.treeData[i].doj = this.treeData[i].doj.substring(0, 10);
              }
              if (this.treeData[i].greenOn) {
                this.treeData[i].greenOn = this.treeData[i].greenOn.substring(0, 10);
              }
            }
          }
          this.model.mergeNodeDataArray(this.treeData);
          this.model.mergeNodeDataArray(this.treeData);
          this.setSelectedNode('');
          console.log(this.model)
        }else{
          this.dangerSwal('You dont have access to see the tree');
        }
      })
    }else{
      this.dangerSwal("Please enter a Display ID");
    }
  }

  cancel(){
    this.memberTreeData.getMemberTreeData(parseInt(this.displayId),0,0).subscribe(val =>{
      if(val){
        console.log(val.data);
        this.model.mergeNodeDataArray(val.data);
        console.log(this.model)
      }
    })
  }
}
