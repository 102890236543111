export class ItemsDetails {
  serial: number;
  catId: string;
  catName: string;
  subCatId: string;
  subCatName: string;
  prodId: string;
  prodName: string;
  prodDesc: string;
  itemId: string;
  itemName: string;
  itemDesc: string;
  hsn: string;
  mrp: number;
  distributorPrice: number;
  bv: number;
  pv: number;
  active: boolean;
  image: string;
  qty: number;
  gstPercentage: number;
}

export class SearchItems {
  catId: string;
  subCatId: string;
  prodId: string;
  itemId: string;
  isActive: boolean;
}

export class CartItems {
  id: string;
  displayId: number;
  cartId: string;
  itemId: string;
  itemName: string;
  itemDesc: string;
  distributorPrice: number;
  qty: number;
  bv: number;
  image: string;
}

export class RemoveItem {
  bv: number;
  cartId: string;
  displayId: number;
  id: string;
  itemId: string;
  qty: number;
}

export class AddToCart {
  bv: number;
  cartId: string;
  displayId: number;
  id: string;
  itemId: string;
  qty: number;
}

export class Purchase {
  displayId: number;
  loginId: number;
  isFirstPurchase: boolean;
  totalPrice: number;
  paymentMode: string;
  shippingAddress: string;
  pinCode: string;
  stateCode: string;
  enteredBy: string;
  cartIds: any;
  gstType: string;
  phNo: string;
  cityVillage: string;
  noCartInvolved: boolean;
  isShippingSameAsCorrespondense: boolean;
  purchasedItems: PurchasedItems[];
}

export class PurchasedItems {
  itemId: string;
  qty: number;
}

export class Orders {
  currentBv: number;
  datewiseTotalBV: number;
  datewiseTotalPrice: number;
  greenBV: number;
  invoiceNo: string;
  orderDate: string;
  orderNo: string;
  outstandingBV: number;
  price: number;
  purchasedItemCount: number;
  name: string;
  stateName: string;
}

export class Product {
  bv: number;
  catName: string;
  cgst: number;
  discount: number;
  hsn: string;
  igst: number;
  itemDesc: string;
  itemName: string;
  itemPrice: number;
  prodDesc: string;
  prodName: string;
  qty: number;
  sgst: number;
  subCatName: string;
}

export class Invoice {
  companyMasterDetails: CompanyMasterDetails;
  invoiceDetails: InvoiceDetails;
  PurchasedItemsDetails: PurchasedItemsDetails[] = [];
  customerName: string;
  address: string;
  contactNo: number;
  email: string;

  products: Product[] = [];
  additionalDetails: string;

  constructor() {
    // Initially one empty product row we will show
    this.products.push(new Product());
  }
}

export class CompanyMasterDetails {
  address: string;
  cin: string;
  contact: string;
  emailId: string;
  enteredAt: string;
  enteredBy: string;
  greenBV: string;
  gstin: string;
  id: number;
  name: string;
  tan: string;
  updatedAt: string;
  updatedBy: string;
  website: string;
}

export class InvoiceDetails {
  billingAddress: string;
  displayId: number;
  enteredAt: string;
  enteredBy: string;
  id: number;
  invoiceDate: string;
  invoiceNo: string;
  name: string;
  orderNo: string;
  pincode: string;
  shippingAddress: string;
  stateCode: string;
  phNo: string;
  gstIn: string;
}

export class PurchasedItemsDetails {
  bv: number;
  catName: string;
  cgst: number;
  discount: number;
  hsn: string;
  igst: number;
  itemDesc: string;
  itemName: string;
  itemPrice: number;
  prodDesc: string;
  prodName: string;
  qty: number;
  sgst: number;
  subCatName: string;
  totalPrice: number;
}

export class StockEntry {
  itemCount: number;
  itemId: string;
  price: number;
  unitId: string;
}

export class StockSearchDetails {
  enteredAt: string;
  enteredBy: string;
  itemCount: number;
  itemId: string;
  itemName: string;
  price: number;
  stockDate: string;
  stockId: string;
  unitId: string;
  updatedAt: string;
  updatedBy: string;
}

export class UnitDetails {
  unitId: string;
  unitName: string;
}

export class PurchaseDeleteGridData {
  displayId: number;
  name: string;
  orderDate: string;
  orderNo: string;
  invoiceNo: string;
  itemName: string;
  qty: number;
  totalPrice: number;
  totalBV: number;
}

export class ItemwiseStock{
  itemId: string;
  itemName: string;
  itemCount: number;
  distributorPrice: number;
  qty: number;
  imagePath: string;
  subTotal: number;
  bv: number;
  totalBV: number;
}
