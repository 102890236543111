import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthSigninV2Component } from './auth-signin-v2/auth-signin-v2.component';
import { FranchiseSigninComponent } from './franchise-signin/franchise-signin.component';

const routes: Routes = [
  {
    path: '',
    component: AuthSigninV2Component,
  },
  {
    path: 'signin-v2',
    component: AuthSigninV2Component,
    pathMatch: 'full',
  },
  {
    path: 'franchise-signin',
    component: FranchiseSigninComponent,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthenticationRoutingModule {}
