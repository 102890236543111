import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoggerService } from 'src/app/theme/shared/log/logger.service';
import { environment } from 'src/environments/environment';
import { AbstractHttpService } from '../../../theme/shared/base/abstract-http';
import { RewardsTable } from '../model/dashboard.model';
import { DailyRepurchaseStatData, Docs, Member, MonthlyRepurchaseStatData, PurchaseStatData, RepurchaseStatData} from '../model/member.model';

@Injectable({
  providedIn: 'root'
})
export class DashboardService extends AbstractHttpService {
  private _memberStatsPost : string = environment.baseUrl + "api/v1/memberstat";
  private _memberrepurchasestat : string = environment.baseUrl + "api/v1/memberpurchasestat";
  private _dailyrepurchasestat : string = environment.baseUrl + "api/v1/dailypurchasestat";
  private _monthlyrepurchasestat : string = environment.baseUrl + "api/v1/monthlypurchasestat";
  private _docs : string = environment.baseUrl + "api/v1/member/docs";
  private _memberSalesIncentiveStat : string = environment.baseUrl + "api/v1/membersalesincentivestat";
  private _firstPurchaseCommission : string = environment.baseUrl + "api/v1/firstpurchasecommissionsamt";
  private _todaybusinessnos: string = environment.baseUrl + "api/v1/todaybusinessnos";
  private _dashboardcommissionsummary: string = environment.baseUrl + "api/v1/dashboardcommissionsummary";
  private _dashboardUnpaidCommissionValue: string = environment.baseUrl + "api/v1/showprevyearcommissionamount";

  constructor(
    protected  http: HttpClient,
    protected  logger: LoggerService
    ) {
    super('DashboardService', http, logger);
  };

  getMemberData(): Observable<any>{
    return this.http.get<any>(this._memberStatsPost);
  }

  getMemberRepurchaseStatData(): Observable<any>{
    const url = this._memberrepurchasestat + '?isFirstPurchase=false';
    return this.http.get<RepurchaseStatData>(url);
  }

  getMemberDailyRepurchaseStatData(): Observable<any>{
    const url = this._dailyrepurchasestat + '?isFirstPurchase=false';
    return this.http.get<DailyRepurchaseStatData>(url);
  }

  getMemberMonthlyRepurchaseStatData(): Observable<any>{
    const url = this._monthlyrepurchasestat + '?isFirstPurchase=false';
    return this.http.get<MonthlyRepurchaseStatData>(url);
  }

  getMemberPurchaseStatData(): Observable<any>{
    const url = this._memberrepurchasestat + '?isFirstPurchase=true';
    return this.http.get<RepurchaseStatData>(url);
  }

  getMemberDailyPurchaseStatData(): Observable<any>{
    const url = this._dailyrepurchasestat + '?isFirstPurchase=true';
    return this.http.get<DailyRepurchaseStatData>(url);
  }

  getMemberMonthlyPurchaseStatData(): Observable<any>{
    const url = this._monthlyrepurchasestat + '?isFirstPurchase=true';
    return this.http.get<MonthlyRepurchaseStatData>(url);
  }

  getUserDocsData(): Observable<any>{
    return this.http.get<Docs>(this._docs);
  }

  getRewardsTableData(): Observable<any>{
    return this.http.get<RewardsTable>(this._memberSalesIncentiveStat);
  }

  getFirstPurchaseCommissionData(): Observable<any>{
    return this.http.get<any>(this._firstPurchaseCommission);
  }

  getTodayBusinessNosData(): Observable<any>{
    return this.http.get<any>(this._todaybusinessnos);
  }

  getDashboardCommissionSummaryData(): Observable<any>{
    return this.http.get<any>(this._dashboardcommissionsummary);
  }

  getDashboardUnpaidCommissionValue(): Observable<any>{
    return this.http.get<any>(this._dashboardUnpaidCommissionValue);
  }
}
